$COL1: #ffffff; //
$COLFLAT1: #ffffff; //
$COL2: #2c3c52; //
$COLFLAT2: #2c3c52; //
$COL3: #4de4da; //
$COLFLAT3: #4de4da; //
$COL4: #242a38; //
$COLFLAT4: #242a38; //
$COL5: #f2f2f2; //
$COLFLAT5: #f2f2f2; //
$COL6: #bcbec0; //
$COLFLAT6: #bcbec0; //
$COL7: #2c3c52; //
$COLFLAT7: #2c3c52; //
$COL8: #61f598; //
$COLFLAT8: #61f598; //
$COL9: #ffffff; //
$COLFLAT9: #ffffff; //
$COL10: #ffffff; //
$COLFLAT10: #ffffff; //
$COL11: #4de4da; //
$COLFLAT11: #4de4da; //
$COL12: #4de4da; //
$COLFLAT12: #4de4da; //
$COL13: #4de4da; //
$COLFLAT13: #4de4da; //
$COL14: #4de4da; //
$COLFLAT14: #4de4da; //
$COL15: #4de4da; //
$COLFLAT15: #4de4da; //
$COL16: #4de4da; //
$COLFLAT16: #4de4da; //
$COL17: #4de4da; //
$COLFLAT17: #4de4da; //
$COL18: #ffffff; //
$COLFLAT18: #ffffff; //
$COL19: #fefefe; //
$COLFLAT19: #fefefe; //
$COL20: #ffffff; //white
$COLFLAT20: #ffffff; //white
$COL21: #000000; //
$COLFLAT21: #000000; //
$COL22: #000000; //
$COLFLAT22: #000000; //
$COL23: #61f598; //
$COLFLAT23: #61f598; //
$COL24: #ffffff; //
$COLFLAT24: #ffffff; //
$COL25: rgba(255,255,255,0.361); //black
$COLFLAT25: #aeaeae; //black
$COL26: rgba(0,0,0,0); //
$COLFLAT26: #808080; //
$COL27: #4de4da; //
$COLFLAT27: #4de4da; //
$breakpoints: (
  small: 0,
  medium: 640px,
  large: 1024px,
  xlarge: 1248px,
  xxlarge: 1936px,
);
$global-width: 1200px;
